@import '../../../assets/sass/theme.scss';

.keybinding {
    font-weight: bolder;
}

.keybinding-icon {
    vertical-align: bottom;
    padding: 4px 15px;
}

.help-key.row {
    color: #ccc;
    padding: 2px;

    &:hover {
        color: #fff;
        background-color: $lighter-2;
    }
}

.help-menu-button {
    padding: 6px 10px;
    color: #ccc;
    display: inline-block;

    &:hover {
        color: #fff;
        background-color: $lighter-2;
        cursor: pointer;
    }
}

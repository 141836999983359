@import "../../assets/sass/theme.scss";

.carmanager-links-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.carmanager-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carmanager-link {
    cursor: pointer;
    margin-bottom: 4px;
}

.carmanager-link-description {
    white-space: normal;
    font-size: 70%;
    max-width: 160px;
}


@import '../../../assets/sass/theme.scss';

.status-bar {
    font-size: 0.75em;
    color: #fff;
    display: flex;
    align-items: center;
    min-height: 24px;
    flex-direction: row;
    padding: 0.25em 0.8em;
    background-color: rgba($color-blue, 0.50);

    ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        white-space: nowrap;
    }

    li {
        list-style: none;
        display: inline-block;
        margin: 0 0.75em;
    }

    li:first-child {
        margin: 0 0.25em;
    }

    span {
        padding-left: 0.4em;
    }

    &-main {
        flex-grow: 1;
    }
}

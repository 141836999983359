/* CanvasTools.css */

/* 1. Editor */
/* 1.1. Cursors */
.CanvasToolsEditor {
    --cursor-pointer: -webkit-image-set(url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABUklEQVRoQ+3YMW6EMBAF0NkTmNOkTkVtCjdUcIFcAY4CNQ1HSNqkSx8JpBwATpDIllhptezCekg8XzIVErI1bz62JZ+MMa9d1z0T6HMioh9jzBsqwgFs81ERDqCUonmeIREOUFUVDcNAbdvCIc6Auq6pKAo4xAXArgU0xBUADbEKQELcBKAg7gIQEJsA6YhdAMmI3QCpiIcAEhEPA6QhvACSEN4AKQgWQAKCDQiNOAQQEnEYIBTiUEAIxOGA/0bsApRlSU3TeN0c/fVtxyZgKT5N0y+l1LePIsuylzzPP33Gbo25C1iK11p/9H3/tDVZiO83AQjF24atAlCKXwUgFX8FQCv+AjCOo9sqJS/YtU3CrYEkSWiaJrjizwnYF7TOL2m4BFCLdwlord+lHlJ7DkabAPQTAaHjiwnEBJgdiL8Qs4Hs4TEBdguZE8QEmA1kD/8FUOpiQO9zcnQAAAAASUVORK5CYII=') 2x), pointer;
    --cursor-move: -webkit-image-set(url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACgElEQVRoQ+2ZS27CMBCGyQFygnZJb1Ep6SY3yJpHeweWQNVjVAHWvkE2hXtQVlwAcQCqiQhygsceP8bqAkuoqpqM/88ez/wuySDwmE6n38fjMavr+iVwaGW4JOQkIL6qqneIWRTFPgZEMIBW/Gg0atZks9lEgQgCIItfr9cNwHg8jgLhDaAS36ZlDAgvAJ34WBDOABTxMSCcAGzEc0NYA7iI54SwApDrvKp/zOfzwWKxaP4EP5fLJdpmQvUJMsBsNns9HA5fmCIhRKYCKMtyh72Tpum+qqoPn2ZKAoCVP5/PQyFErpnsguwAOkdZltvT6fTk07GNAG3awEpyAMDO+aSTFkDOeU4AH++EAsjVBnwNJwD4J1fvpATol8okSVgBLpeLs3e6A1DV+RgArgawA4A1qVgALhA3AF2HBQDKsGlkcjxIIXnYuNhGmcketN3VBJHn+QA+MLbbbfOhDFV8KkRiEk8RwPUMBeIGAA+vVisuLU5xJ5PJAG54RVH81nU9VAXppBAGoTNlctAsyzoptNuhNqijBc5Of1DEwzt3h1gFEfsQU8V3AOTD3IeIWUZtxN8BYBCxAGzFKwFUEDEAXMSjAH0IqAScZg5S1lRtsDL2n+w0Wip1NdjoESJdaJzEa1NIpma+Uj5jTYrS/Yw70Aa5Xuo/saBCiDfkUv+DvZOm6SHKpb4VEPjfKs5pIy8IeQf6EC7eybVUeh1i1cvtTthAcIgnH2JfCC7xXgA67yQDc4r3BjBBcIsPAoBBxBAfDKAPAb+7ehtK8/Iqo7oJel+zBqnzJiDrPmAKeP2iO/exB6Y52HbAZuJQzwbfgVDCqHEeANSV4nrusQNcK0uN+wdgZhRePQu00wAAAABJRU5ErkJggg==') 2x) 8 8, move;
    --cursor-resize: -webkit-image-set(url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADKElEQVRoQ+2ZK6waQRSGB8W25iJBgWiTrQKNKWo1ZF1LwuM2teARgMCDbxdIqCSgUSgsoLqiTWhCAhLUBbXN2bBkgN15MWy5KVjm8X9zHvMPBJDkT7FY/LZcLj+ORqP3kpd2XS4gcxMQbxjGM6ypadovPyCkATjic7mcfSbdbtcXCCkAuPhOp2MD5PN5XyCuBnAT76SlHxBXAZDE+wUhDMAi3g8IIQBHfCgUQuVymamRNZtNtNlspBc2NwDeKpmUuwyS2WK5AURF32reA+BWJ8u6LjUCh5z/wrqgyLhDTbwTmUsEwAqWCiqyOTbHEi1sT2Fn3ebmAKIG0FWYI/7p6Qltt1tYmwoAcyaTySfTNN/ABFVVX5LJZM8wjK8M0bGcvXgjcSEMv2FjsRiq1+tEgEqlEu33+z9BeDweR6lUytY7Ho/RfD63QXRd/9BoNP4QQKxqtYoWiwW3ATwBOLcHtVqNCgACV6uVAi40nU6faBwOh7YrjUQiL6ZpvqUBwH68BvAI4OZtaADZbHbQ6/XSg8HgQrwjFiAymQwqFovfDcPw6mZ2BGA/XituA3gZMxoAnH4wGFRmsxkxzROJBNrv96QonADwQARIrpIGgBCySqUSAqNG+oDha7VapFq6AGCFOAJA7rXb7RMddwTwezQauV50Jyl0DkEDgBRSFEWZTqcsKbRzWqzLYFIKeYo/CamTSjgEDcCHIiaKv8jJcwgagHNhrddrBdLPrY0WCgUUDodJpw/LHCOAtVGqeNeiwiGi0Sj1HjhcZKZpmgp0G/wig+6kqupO13WV8yJjEu/ZFfAnIzwDOazEZwA5RGaXTCZ/EHo/XgoWPE8PT05m8URh/8jMcYmnnqzPdppbPBUAu6Vf54OG2Nzv5Euqz78TnZ4y/j8AST9sCRWsWxiEIoDfE+BGWT7gWOF5qmmaNPFMXchLnJt38hrLaw9YDsQZIxQBZzILxC3FXxUBFohbi5cCgF12z7gV90O8NIBzCMuynJ9HpBastC5EK2z4Xna38drzqiJ2W/TwR3fK6w3L02FYxkoHYNlU5pgHgMzTFFnrEQGRU5M559VH4C9ocYlPRB5IuQAAAABJRU5ErkJggg==') 2x) 8 8, nesw-resize;
    --cursor-delete: -webkit-image-set(url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABn0lEQVRoQ+3YMW6EMBAAwL0XmNekhYraFG6o4AP5AvATqGl4ArRJlz4SSHkAvIDIljjlBHesAQevhKuTzpidXa8R3IQQdVmWHhAdNwAYhRANVYQCyORTRSgAYwyGYSCJUIAkSaBtWyiKghziDkjTFKIoIod4AMhe0ESo/tk45L13jxlAEzG6rguehz+F67qGpmnkbcwBNBCqf+T2ww45N8sy8wAkwm4AAjHK7aO7heQ2Mr6F/m6JF41tZxMv7WfN0wnbErvnLZ5Cz1a1EaEFQPTE7ozqLqANsA2xCWATYjPAFsQugA2I3YCzEYcAzkQcBjgLcSjgDMThgP9GoABxHEOe57oPSTXf9NeOVcAUvO/734yxny2KIAjewzD82nLt2jUvAVPwnPPPqqre1hY74/+nAArBT29Fs9dCKsEvAigFPwNQC/4B0HWdOiptbtilQ0I1seM40Pc9ueDvFZA/qGV+qoaqANXgVQU45x+2PqQwD8ZDPrBibmRqzgUwlVnsulcFsJkyNe+qgKnMYte9KoDNlKl5VwVMZRa7LvkK/AIya4BAvmH9YQAAAABJRU5ErkJggg==') 2x), pointer;
    --cursor-add: -webkit-image-set(url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACEElEQVRoQ+2YsW7CMBCGnZEs4Wm6hol2DEOkKkMEL9BXAN4EmCqxMLZbsrZb90og9QFggTHVWZgGY+JLbNexlJOQIsU+33f/ne3gxXGcrdfrAXHUPEJIEcdxrgNitVo9H4/HRz4Xvu+/p2n6aiJHFAAc64A4qxnygerwfQ+eAgRBQA6HgzIEAygKmhNqnucp+61SjgJMp1Oy3W7JcrlUWswqwGw2I+PxWAnCOgBIVRPir1ZKOvMldKcEQH1lu5QQKMCsBkQRhiEZDK534bKv8jP4z7KM5HlO20M5+rMT2gP8QkgI4dyqwGCd+XxuHgBZTu0GQEAUUD58CYGizCDjsJUygxKCn/ESKpdBRTm1s4lFNYzpiVZso1UNKINoPYCsJ5wAqIKwdpkTnQOyQ0ZUTnCdPp1OT/zcXq/3ZvQ63QRAVk6yBOh6L7xK1HEua+w6vpqMVQawrYQWAJsQ2gBsQWgFsAGhHeC/IVAAk8mELBaLJpuE0jc2ZkEpAAt+OBx+B0Hwg3HKjxmNRi9Jknw1mSubUwnAgo+i6HOz2TzInNl4fxfAheDZV9HNZ6ErwQsBXAr+BsC14K8Adrsd3Srb3LCiTYI2cb/fJ/v93rngLwrAg2uZZ2pQBVwNnioQRdFHWw8pzMGo5Q9WzEKmxnQApjKL9dspgM2UqXGdAqYyi/XbKYDNlKlxnQKmMov167wCv7Mu7kBLP7biAAAAAElFTkSuQmCC') 2x), pointer;
}

/* 1.2. Layout */
.CanvasToolsEditor {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr; 
    width: 100%;
    height: 100%;
    box-sizing: content-box;
}

.CanvasToolsEditor * {
    box-sizing: content-box;
}

.CanvasToolsEditor canvas {
    position: relative;
    grid-row: 1;
    grid-column: 1;
    z-index: 100;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: #111;
}

.CanvasToolsEditor svg {
    position: relative;
    grid-row: 1;
    grid-column: 1;
    z-index: 101;
    width: 100%;
    height: 100%;
}

.CanvasToolsEditor svg {
    cursor: var(--cursor-pointer);
}

.CanvasToolsEditor svg title {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    pointer-events: none;
}

.CanvasToolsContainer {
    overflow: auto;
    height: 100%;
    width: 100%;
}

/* 2. RegionsManager

.regionManager
-->.regionStyle
    --> .tagsLayer
    --> .dragLayer
    --> .anchorsLayer
    
--> .menuLayer
    --> .menuRectStyle
*/

/* 2.1. General settings and layout */
.regionManager {
    pointer-events: none;
}

.regionStyle {
    pointer-events: visiblePainted;
}

.dragRectStyle {
    fill: transparent; 
    stroke-width: 0;
    pointer-events: all;
    cursor: var(--cursor-move);
}

.dragPointStyle {
    stroke-width: 0;
    pointer-events: all;
    cursor: var(--cursor-move);
    filter: url(#black-glow);
}

.tagsLayer {
    pointer-events: none;
}

.primaryTagRectStyle {
    stroke-width: 2;
    stroke-dasharray: 0.5 4;
    stroke-linecap: round;
    filter: url(#black-glow);
}

.primaryTagPointStyle {
    stroke-width: 1; 
}

.primaryTagTextStyle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 9pt;
    fill: #fff;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    pointer-events: none;
}

.secondaryTagTextStyle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 8pt;
    fill: #fff;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    pointer-events: none;
}

.primaryTagTextStyle::selection {
    background: none;
    fill: #fff;
}

.secondaryTagStyle {
    stroke-width: 0;
    pointer-events: none;
}

.anchorStyle {
    stroke-width: 2;
}

.anchorStyle.ghost {
    cursor: var(--cursor-resize);
}

.anchorStyle.ghost.delete {
    cursor: var(--cursor-delete);
}

.anchorStyle.ghost.add {
    cursor: var(--cursor-add);
}

.anchorStyle.ghost {
    stroke-width: 0;
}

.primaryTagBoundRectStyle {
    stroke-width: 0.5;
}

.primaryTagPolylineStyle, .primaryTagPolygonStyle {
    stroke-width: 2;
    stroke-linecap: round;
    stroke-dasharray: 0.5 4;
}

/* 2.2. Default colors */
.regionManager {
    --default-color-pure: rgb(128, 128, 128);
    --default-color-accent: rgba(128, 128, 128, 0.8);
    --default-color-dark: rgba(64, 64, 64, 0.8);
    --default-color-shadow: rgba(128, 128, 128, 0.4);
    --default-color-highlight: rgba(128, 128, 128, 0.2);
    --default-color-white: rgb(255, 255, 255);
    --default-color-transparent: rgba(255, 255, 255, 0);
    --default-color-ghost: rgba(255, 255, 255, 0.5);
    --default-color-delete: rgba(216, 24, 65, 1.0);
    --default-color-add: rgba(21, 127, 240, 1.0);
}

/* 2.2.1. Shared colors */
.secondaryTagStyle {
    fill: var(--default-color-accent);
}

.anchorStyle {
    stroke: var(--default-color-dark);
    fill: var(--default-color-pure);
}

.regionStyle:hover .anchorStyle {
    stroke: var(--default-color-white);
}

.anchorStyle.ghost,
.anchorStyle.ghost:hover,
.regionStyle.selected .anchorStyle.ghost,
.regionStyle.selected .anchorStyle.ghost:hover {
    fill: var(--default-color-ghost);
}

.anchorStyle:hover {
    stroke: var(--default-color-white);
}

/* 2.2.2. Rect region colors */
.primaryTagRectStyle {
    fill: var(--default-color-shadow);
    stroke:var(--default-color-accent);
}

.regionStyle:hover .primaryTagRectStyle {
    fill: var(--default-color-highlight);
    stroke: var(--default-color-white);
}

.regionStyle.selected .primaryTagRectStyle {
    fill: var(--default-color-highlight);
    stroke-dasharray: none;
}

.primaryTagTextBGStyle {
    fill: var(--default-color-dark);
}

.anchorBoneStyle {
    fill: var(--default-color-transparent);
}

/* 2.2.3. Point region  colors */
.primaryTagPointStyle {
    fill: var(--default-color-pure);
    stroke:var(--default-color-white);
}

.dragPointStyle {
    fill: var(--default-color-ghost);
    opacity: 0.5;
}

.regionStyle:hover .dragPointStyle,
.regionStyle.selected .dragPointStyle {
    fill: var(--default-color-ghost);
    opacity: 1.0;
}

/* 2.2.4. Polyline, polygon region colors */
.primaryTagBoundRectStyle {
    fill: var(--default-color-shadow);
    stroke:var(--default-color-accent);
    opacity: 0.25;
}

.regionStyle.selected .primaryTagBoundRectStyle {
    fill: var(--default-color-highlight);
}

.regionStyle:hover .primaryTagBoundRectStyle {
    fill: var(--default-color-highlight);
    stroke: var(--default-color-white);
}

.primaryTagPolylineStyle {
    fill: var(--default-color-transparent);
    stroke: var(--default-color-pure);
}

.regionStyle.selected .primaryTagPolylineStyle {
    filter: url(#black-glow);
    stroke-dasharray: none;
}

.primaryTagPolygonStyle {
    fill: var(--default-color-shadow);
    stroke: var(--default-color-pure);
}

.regionStyle.selected .primaryTagPolygonStyle {
    fill: var(--default-color-highlight);
    filter: url(#black-glow);
    stroke-dasharray: none;
}

.regionStyle:hover .primaryTagPolygonStyle {
    fill: var(--default-color-highlight);
}

.regionStyle:hover .anchorStyle.ghost.delete,
.regionStyle.selected .anchorStyle.ghost.delete,
.anchorStyle.ghost.delete,
.anchorStyle.ghost.delete:hover {
    stroke: var(--default-color-delete);
    stroke-width: 2px;
    fill: var(--default-color-transparent);
}

.regionStyle:hover .anchorStyle.ghost.add,
.regionStyle.selected .anchorStyle.ghost.add,
.anchorStyle.ghost.add,
.anchorStyle.ghost.add:hover {
    stroke: var(--default-color-add);
    stroke-width: 2px;
    fill: var(--default-color-transparent);
}

.anchorLineStyle {
    fill: none;
    stroke-width: 5;
    stroke: var(--default-color-transparent);  
}

svg:not(:root) .menuLayer {
    overflow: visible;
}

.menuRectStyle { 
    stroke-width:0;
    fill: rgba(64, 64, 64, 0.8);
    filter: url(#black-glow); 
}

.menuItemBack {
    stroke-width: 1.5;
    stroke: rgba(198, 198, 198, 0.2);
    fill:  rgb(32, 32, 32);
}

.menuIcon {
    font-family: 'Segoe UI Emoji', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 10pt;
    fill: rgb(64, 64, 64);
}

.menuItem {
    stroke-width: 1.5;
    stroke: rgba(198, 198, 198, 0.2);
    fill:transparent;
}

.menuItem:hover {
    stroke: rgba(198, 198, 198, 0.8);
}

/* Freezing regions */ 

.regionManager.frozen .regionStyle.old,
.regionManager.frozen .regionStyle.old .dragRectStyle,
.regionManager.frozen .regionStyle.old .dragPointStyle {
    pointer-events: none;
}

.regionManager.frozen .regionStyle.old .dragRectStyle, 
.regionManager.frozen .regionStyle.old .anchorStyle.TL, 
.regionManager.frozen .regionStyle.old .anchorStyle.BR, 
.regionManager.frozen .regionStyle.old .anchorStyle.TR, 
.regionManager.frozen .regionStyle.old .anchorStyle.BL {
    cursor: default; 
}

.regionManager.frozen .anchorStyle.ghost {
    display: none;
}

.regionManager.frozen .regionStyle.old, 
.regionManager.frozen .regionStyle.old:hover{
    opacity: 0.5;
}

.regionManager.frozen .regionStyle.old .primaryTagRectStyle,
.regionManager.frozen .regionStyle.old .primaryTagPointStyle,
.regionManager.frozen .regionStyle.old .primaryTagPolylineStyle,
.regionManager.frozen .regionStyle.old .primaryTagPolygonStyle {
    stroke-width: 1;
    stroke-dasharray: 0 0;
}

.regionManager.frozen .regionStyle.old .anchorStyle {
    display: none;
}

.regionManager.frozen .regionStyle.old .primaryTagTextStyle .secondaryTagTextStyle,
.regionManager.frozen .regionStyle.old .primaryTagTextBGStyle {
    opacity: 0.25;
}

/* AreaSelector

.areaSelector
-->.rectSelector
    --> .maskStyle
        [mask]
            .maskInStyle
            .maskOutStyle
        .crossStyle
            line
            line
-->.rectCopySelector
    --> .crossStyle
            line
            line
        .copyRectStyle
-->.pointSelector
    --> .crossStyle
        .pointStyle
-->.polylineSelector
    --> .polylineStyle
        .polylineGroupStyle
        --> .polylinePointStyle
        .nextSegmentStyle
        .nextPointStyle
-->.polygonSelector
    --> .polygonStyle
        .polygonGroupStyle
        --> .polygonPointStyle
        .nextSegmentStyle
        .nextPointStyle
*/

#selectionOverlay {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.crossStyle line {
    stroke-width:1;
    stroke-dasharray: 3 3;
    stroke: #666;
    pointer-events: none; 
}

.selectionBoxStyle {
    fill: #fff;
    fill-opacity: 0.25;
    stroke-width: 0;
    pointer-events: none;
}

.rectCopySelector .copyRectStyle {
    stroke-width:1;
    stroke: #666;
    fill: transparent;
    pointer-events: none; 
}

.pointSelector .pointStyle {
    stroke-width:2;
    stroke: rgba(21, 127, 240, 1.0);
    fill: transparent;
    pointer-events: none; 
}

.polylineSelector .polylineStyle {
    fill: transparent;
    stroke-width: 2px;
    stroke:  rgba(21, 127, 240, 0.5);
    pointer-events: none;
}

.polylineSelector .polylinePointStyle {
    fill:  rgba(21, 127, 240, 1.0);
    stroke-width: 0;
    pointer-events: none;
}

.polylineSelector .nextSegmentStyle {
    stroke-width:2;
    stroke-dasharray: 3 3;
    stroke: rgba(21, 127, 240, 1.0);
    pointer-events: none;
}
.polylineSelector .nextPointStyle {
    stroke-width:2;
    r: 6px;
    stroke: rgba(21, 127, 240, 1.0);
    fill: transparent;
    pointer-events: none;
}

.polygonSelector .polygonStyle {
    fill: rgba(255,255,255, 0.2);
    stroke-width: 2px;
    stroke:  rgba(21, 127, 240, 0.5);
    pointer-events: none;
}

.polygonSelector .polygonPointStyle {
    fill:  rgba(21, 127, 240, 1.0);
    stroke-width: 0;
    pointer-events: none;
}

.polygonSelector .nextSegmentStyle {
    stroke-width:2;
    stroke-dasharray: 3 3;
    stroke: rgba(21, 127, 240, 1.0);
    pointer-events: none;
}
.polygonSelector .nextPointStyle {
    stroke-width:2;
    r: 6px;
    stroke: rgba(21, 127, 240, 1.0);
    fill: transparent;
    pointer-events: none;
}

/* Toolbar 

.toolbarLayer
--> .toolbarBGStyle
--> .iconsLayerStyle
    --> .iconStyle
        --> .iconBGRectStyle
            .iconImageStyle
*/
.toolbarBGStyle {
    fill: #666;
}

.iconStyle {
    pointer-events: all;
}

.iconStyle.selector .iconBGRectStyle{
    fill: transparent;
}

.iconStyle.selector:hover .iconBGRectStyle{
    fill: rgba(68, 68, 68, 0.5);
}

.iconStyle.selector.selected .iconBGRectStyle {
    fill: #333;
}

.iconStyle .iconImageStyle * {
    stroke: #fff;
}


.iconStyle.switch .iconBGRectStyle{
    fill: transparent;
}

.iconStyle.switch:hover .iconBGRectStyle{
    fill: rgba(68, 68, 68, 0.5);
}

.iconStyle.switch .iconImageStyle * {
    stroke: #333;
}

.iconStyle.switch.selected .iconImageStyle * {
    stroke: rgb(14, 186, 253);
    stroke-width: 1.5;
}

.iconStyle .iconImageStyle .accent-f {
    fill: rgba(21, 127, 240, 1.0);
}

.iconStyle .iconImageStyle .accent-s {
    stroke: rgba(21, 127, 240, 1.0);
}

.iconStyle.separator line {
    stroke: #333;
    stroke-width: 0.5px;
}

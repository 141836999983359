@import "../../../node_modules/react-day-picker/lib/style.css";
@import "../../assets/sass/theme";

.external-projects-container {
    display: flex;
    flex-direction: column;
    //height: 90vh;
}

.external-projects-toolbar {
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .space {
        flex-grow: 2;
    }
}

.external-projects-content {
    //overflow-y: auto;
}

.external-projects-table {
    height: 80vh !important;
}

.DayPickerInput {
    display: inline-block;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
    background: rgba(0, 0, 0, 0.85);
}

.DayPicker:not(.DayPicker--interactionDisabled)
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: $color-blue;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $color-blue;
}

.DayPicker-Day--selected {
    background-color: $color-blue;
}


@import '../../../assets/sass/theme.scss';

.platform-web, .platform-win32 {
    .title-bar {
        min-height: 34px;
        height: 34px;

        &-controls li {
            padding: 0.4em 0.8em;
        }
    }
}

.platform-darwin {
    .title-bar {
        &-controls li {
            font-size: 0.9em;
            padding: 0 0.8em;
        }
    }
}

.title-bar {
    color: #ccc;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    &-main {
        -webkit-app-region: drag;
        -webkit-user-select: none;

        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        text-align: center;
        font-size: 0.80em;
        white-space: nowrap;
        cursor: pointer;
    }

    &-icon {
        -webkit-app-region: drag;
        -webkit-user-select: none;

        padding: 0.4em 0.8em;
        color: $color-green;
    }

    @media (max-width: 640px) {
        &-menu {
            display: none;
        }
    }

    &-controls {
        display: flex;
        flex-direction: row;

        ul {
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }

        li {
            color: #ccc;
            list-style: none;
            display: inline-block;

            &:hover {
                color: #fff;
                background-color: $lighter-2;
                cursor: pointer;
            }
        }

        .btn-window-close {
            &:hover {
                background-color: $color-red;
                color: #fff;
            }
        }
    }
}

.menu-item {
    &-container {
        display: flex;
        flex-direction: row;
    }

    &-label {
        flex-grow: 1;
    }

    &-accelerator {
        color: #999;
        text-align: right;
        margin-left: 2em;
    }

    &-checkbox {
        font-size: 0.8em;
        position: absolute;
        margin-left: -1.5em;
    }
}

.rc-menu {
  outline: none;
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  color: #ccc;
  font-size: 12px;

  &-hidden, &-submenu-hidden {
    display: none;
  }

  &-collapse {
    overflow: hidden;
    &-active {
      transition: height .3s ease-out;
    }
  }

  &-item-group-list {
    margin: 0;
    padding: 0;
  }

  &-item-group-title {
    color: #999;
    line-height: 1.5;
    padding: 8px 10px;
  }

  &-item-active,
  &-submenu-active > &-submenu-title {
    background-color: $lighter-2;
    cursor: pointer;
  }

  &-item-selected {
    background-color: $lighter-3;
    // fix chrome render bug
    transform: translateZ(0);
  }

  &-submenu-selected {
    background-color: $lighter-3;
  }

  & > li {
    &-submenu {
        padding: 0;
    }
  }

  &-horizontal, &-sub,  &-vertical, &-sub,  &-vertical-left, &-sub,  &-vertical-right, &-sub {
    min-width: 160px;
    margin-top: 0;
  }

  &-item, &-submenu-title {
    margin: 0;
    position: relative;
    display: block;
    padding: 0 1em;
    height: 34px;
    line-height: 34px;
    white-space: nowrap;

    // Disabled state sets text to gray and nukes hover/tab effects
    &.rc-menu-item-disabled, &.rc-menu-submenu-disabled {
      color: #777 !important;
    }
  }
  & > &-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    padding: 0;
    line-height: 0;
    border-top: 1px solid $lighter-2;
    border-bottom: 1px solid $darker-2;
  }

  &-submenu {
    &-popup {
      position: absolute;
      z-index: 1;
      background-color: #272B30;
      border: 1px solid $darker-1;
      box-shadow: 0px 2px 10px $darker-5;

      .submenu-title-wrapper {
        padding-right: 20px;
      }
    }
    > .rc-menu {
      background-color: $lighter-1;
    }
  }

  .rc-menu-submenu-title, .rc-menu-item {
    .anticon {
      width: 14px;
      height: 14px;
      margin-right: 8px;
      top: -1px;
    }
  }

  &-horizontal {
    border: none;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;

    & > .rc-menu-submenu, & > .rc-menu-item {
      display: inline-block;
      vertical-align: bottom;
    }

    &:after {
      content: "\20";
      display: block;
      height: 0;
      clear: both;
    }
  }

  &-vertical,
  &-vertical-left,
  &-vertical-right,
  &-inline {
    padding: 12px 0;
    & > .rc-menu-item, & > .rc-menu-submenu > .rc-menu-submenu-title {
      padding: 12px 8px 12px 24px;
    }
    .rc-menu-submenu-arrow {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome, sans-serif;
      font-size: inherit;
      vertical-align: baseline;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
      position: absolute;
      right: 16px;
      line-height: 34px;
      &:before {
        content: "\f0da";
      }
    }
  }
  &-inline {
    .rc-menu-submenu-arrow {
      transform: rotate(90deg);
      transition: transform .3s;
    }
    & .rc-menu-submenu-open > .rc-menu-submenu-title {
      .rc-menu-submenu-arrow {
        transform: rotate(-90deg);
      }
    }
  }

  &-vertical, &-sub,
  &-vertical-left, &-sub,
  &-vertical-right, &-sub {
    padding: 0;
  }

  &-sub, &-inline {
    padding: 0;
    border: none;
    box-shadow: none;

    & > .rc-menu-item, & > .rc-menu-submenu > .rc-menu-submenu-title {
      padding: 0 2em;
      line-height: 34px;
    }
  }

  &-open {

    &-slide-up-enter, &-slide-up-appear {
      opacity: 0;
      animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
      animation-play-state: paused;
    }

    &-slide-up-leave {
      opacity: 1;
      animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
      animation-play-state: paused;
    }

    &-slide-up-enter, &-slide-up-enter-active, &-slide-up-appear, &-slide-up-appear-active {
      animation-name: rcMenuOpenSlideUpIn;
      animation-play-state: running;
    }

    &-slide-up-leave, &-slide-up-leave-active {
      animation-name: rcMenuOpenSlideUpOut;
      animation-play-state: running;
    }

    @keyframes rcMenuOpenSlideUpIn {
      0% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
      }
      100% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
      }
    }
    @keyframes rcMenuOpenSlideUpOut {
      0% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
      }
      100% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
      }
    }

    &-zoom-enter, &-zoom-appear {
      opacity: 0;
      animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
      animation-play-state: paused;
    }

    &-zoom-leave {
      animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
      animation-play-state: paused;
    }

    &-zoom-enter, &-zoom-enter-active, &-zoom-appear, &-zoom-appear-active {
      animation-name: rcMenuOpenZoomIn;
      animation-play-state: running;
    }

    &-zoom-leave, &-zoom-leave-active {
      animation-name: rcMenuOpenZoomOut;
      animation-play-state: running;
    }

    @keyframes rcMenuOpenZoomIn {
      0% {
        opacity: 0;
        transform: scale(0, 0);
      }
      100% {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
    @keyframes rcMenuOpenZoomOut {
      0% {

        transform: scale(1, 1);
      }
      100% {
        opacity: 0;
        transform: scale(0, 0);
      }
    }
  }
}
